.ticket-detail {
  margin: 30px;
  padding: 42px 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include shadow-md();
  @include border-radius-md();

  &__top {
    &__heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 21px;

      &__left-column {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 3px;

        &__title {
          @include font-bold();
          font-size: 2em;
          color: $primary;
        }

        &__date {
          @include font-bold();
          color: $dark-theme;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 1.1em;
          margin-bottom: 5px;
        }

        &__user {
          color: $primary;
          font-weight: 900;
        }
      }
    }
  }

  &__bottom {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-left: 21px;
    }
  }
}
