.list-wrapper__user-list-item {
  display: grid;
  grid-template-columns: 300px 200px 300px 200px 200px 100px;
  font-size: 1.3em;
  padding: 15px;
  border: 1px solid transparent;
  transition: 0.5s ease-in-out;
  margin-bottom: 15px;
  width: 1200px;

  &:hover {
    @include shadow-md();
    border: 1px solid $primary;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    svg {
      margin-right: 15px;
      margin-left: 15px;
      color: $primary;
    }
  }
}

.list-wrapper__auto-message-list-item {
  display: grid;
  grid-template-columns: 320px 250px 300px 50px 50px;
  font-size: 1.3em;
  padding: 15px;
  border: 1px solid transparent;
  transition: 0.5s ease-in-out;
  margin-bottom: 15px;
  width: 1200px;

  &:hover {
    @include shadow-md();
    border: 1px solid $primary;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    svg {
      margin-right: 15px;
      margin-left: 15px;
      color: $primary;
    }
  }
}

.list-wrapper__push-notification-list-item {
  display: grid;
  grid-template-columns: 150px 300px 300px 250px 150px 50px 50px;
  grid-gap: 30px;
  font-size: 1.3em;
  padding: 15px;
  border: 1px solid transparent;
  transition: 0.5s ease-in-out;
  margin-bottom: 15px;
  width: 1200px;
  align-items: center;

  &:hover {
    @include shadow-md();
    border: 1px solid $primary;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    svg {
      margin-right: 15px;
      margin-left: 15px;
      color: $primary;
    }
  }
}

.list-item-actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s ease-in-out;
  padding: 15px;
  @include border-radius-sm();

  &:hover {
    @include shadow-md();
  }
}
