.centered-flex-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-flex-wrapper,
.flex-columns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
