.grid-1 {
  display: grid;
  grid-template-columns: 1fr;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-3fr-1fr {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.grid-1fr-400px {
  display: grid;
  grid-template-columns: 1fr 400px;
}

.grid-1fr-500px {
  display: grid;
  grid-template-columns: 1fr 500px;
}

.grid-500-1fr {
  display: grid;
  grid-template-columns: 500px 1fr;
}

.grid-1fr-3fr {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.grid-2fr-3fr {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.grid-gap-42 {
  grid-gap: 42px;
}
