.chat-message-wrapper {
  margin: 15px 0px;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 15px;

  &__body {
    &__name {
      @include font-bold();
      margin-bottom: 7px;

      &__timestamp {
        font-size: 0.9em;
        color: $primary;
        font-style: italic;
        margin-left: 15px;
      }
    }
  }
}
