.image-uploaders {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 21px;

  .modal-form-image {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    img {
      background-color: $primary;
      width: 100%;
      max-height: 160px;
      object-fit: cover;
      @include border-radius-sm();
    }

    .remove-image-link {
      text-align: center;
      a {
        svg {
          margin-left: 5px;
        }
        font-size: 1.3em;
        color: $warning;
        cursor: pointer;
        font-weight: 900;
        transition: 0.5s ease-in-out;
        text-transform: uppercase;

        &:hover {
          color: $dark-warning;
        }
      }
    }
  }

  .dz-progress {
    display: none;
  }

  .dropzone .dz-preview.dz-image-preview {
    background: transparent;
  }

  .dropzone .dz-preview .dz-remove {
    text-decoration: none;
    color: $primary;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 10px;
    transition: 0.5s ease-in-out;

    &:hover {
      color: $dark-primary;
      text-decoration: none;
    }
  }
}

.dropzone.dz-clickable {
  min-width: 120px !important;
}
