.chat-admin-links {
  font-size: 1.5em;
  font-weight: 900;
  a {
    margin-right: 10px;
  }

  &__warning {
    color: $warning;

    &:hover {
      color: $dark-warning;
    }
  }
}
