.markdown-icon {
  svg {
    height: 15px;
    width: 24px;
    margin-left: 10px;
    margin-bottom: -2px;
  }
}

.pdf-file-icon {
  margin-top: 21px;
  a {
    svg {
      font-size: 4em;
    }
  }
}
