@mixin base-input() {
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-bottom: 2px solid $primary;
  transition: 0.5s ease-in-out;
  outline: none;
  width: calc(100% - 24px);
  font-size: 1.5rem;
  padding: 12px;
  background-color: transparent;
  @include border-radius-sm();

  &:focus {
    border-bottom: 2px solid $dark-primary;
    @include shadow-md();
  }
}

@mixin rounded-input() {
  border-top-left-radius: 42px;
  border-bottom-left-radius: 42px;
  border: 2px solid $primary;
  font-size: 1.5em;
  color: $primary;
  outline: none;
  padding: 10px 20px;
  font-weight: 900;
  width: 70%;
}
