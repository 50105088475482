@mixin border-radius-sm() {
  border-radius: 3px;
}

@mixin border-radius-md() {
  border-radius: 7px;
}

@mixin border-radius-lg() {
  border-radius: 10px;
}

@mixin border-radius-circle() {
  border-radius: 100px;
}
