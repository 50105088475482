.navigation-wrapper {
  display: flex;
  width: 100vw;
  padding: 21px;
  justify-content: space-between;
  align-items: center;
  @include shadow-md();
  margin-bottom: 42px;

  .sign-out-nav-link {
    width: 80%;
    text-align: right;
    padding-right: 42px;

    a {
      font-size: 1.5em;
      @include font-bold();

      svg {
        margin-right: 5px;
      }
    }
  }

  .nav-links-wrapper {
    display: flex;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5em;
      @include font-bold();
      margin-right: 15px;
      width: 130px;

      svg {
        width: 21px;
        margin-right: 5px;
      }
    }
  }
}
