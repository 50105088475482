.guide-content-wrapper {
  font-size: 1.5em;
  max-width: 90vw;
  p {
    margin: 15px 0px;
  }

  img {
    max-width: 100%;
  }
}

.guide-nested-form-group {
  max-width: 90%;
  display: flex;
  align-items: center;

  input {
    @include base-input();
    margin: 15px 0px;
  }

  button {
    background-color: transparent;
    border: 0px solid transparent;

    svg {
      font-size: 2.5rem;
      color: $primary;
      margin-left: 15px;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      &:hover {
        color: $dark-primary;
      }
    }
  }

  a {
    svg {
      font-size: 2.5rem;
      color: $warning;
      margin-left: 15px;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      &:hover {
        color: $dark-warning;
      }
    }
  }
}

.guide-nested-form-link {
  @include font-bold();
  font-size: 1.5em;
}

.list-item-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  font-size: 2em;
  margin: 20px 0px;
}

.guide-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
