.btn-primary {
  @include button();
}

.btn-warning {
  @include button();
  background-color: $warning;
  border: 1px solid $warning;

  &:hover {
    background-color: $dark-warning;
    border: 1px solid $dark-warning;
  }
}

.btn-loading {
  @include button();
  background-color: $dark-primary;
  cursor: not-allowed;
}
