@mixin button() {
  background-color: $primary;
  border: 1px solid $primary;
  color: $background;
  font-weight: 900;
  font-size: 1.6em;
  padding: 6px 12px;
  @include border-radius-sm();
  transition: 0.5s ease-in-out;
  outline: none;
  cursor: pointer;

  &:hover {
    @include shadow-md();
    background-color: $dark-primary;
    border: 1px solid $dark-primary;
    color: white;
  }

  svg {
    margin-left: 5px;
  }
}
