.homepage-wrapper {
  width: 100vw !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;

  &__logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: $primary;
  }

  .sign-in-form {
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 450px;

      .headline {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 3em;
        color: $primary;
        svg {
          width: 130px;
          margin-right: 10px;
        }

        margin-bottom: 50px;
      }

      .subtitle {
        @include font-bold();
        width: 100%;
        text-align: center;
        font-size: 1.8em;
        color: $dark-theme;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 50px;
      }
    }
  }
}

.password-page-wrapper {
  width: calc(100vw - 30px) !important;
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  padding: 0px 15px;

  &__logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: $primary;
  }

  .sign-in-form {
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 100%;

      .headline {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 3em;
        color: $primary;
        svg {
          width: 130px;
          margin-right: 10px;
        }

        margin-bottom: 50px;
      }

      .subtitle {
        @include font-bold();
        width: 100%;
        text-align: center;
        font-size: 1.8em;
        color: $dark-theme;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 50px;
      }
    }
  }
}
