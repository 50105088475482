@mixin font-primary() {
  font-family: "montserrat";
}

@mixin font-bold() {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
}

@mixin font-code() {
  font-family: "Anonymous Pro", monospace;
}
