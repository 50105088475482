.markdown-container {
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 21px;
  max-width: calc(100vw - 673px);

  pre {
    padding: 21px;
    @include border-radius-sm();
    @include font-code();
    font-weight: 700;
    background-color: $dark-theme;
    color: white;
  }

  img {
    max-width: 100%;
    @include border-radius-sm();
  }
}
