.search-select__multi-value__label {
  font-size: 1.3em !important;
}

.search-select__input {
  input {
    font-size: 1.3em !important;
  }
}

.search-select__control--is-focused {
  border: 1px solid $primary !important;
  box-shadow: 0 0 0 1px $primary !important;
}
