.rating-detail-modal {
  &__title {
    @include font-bold();
    color: $primary;
    font-size: 2em;
    margin-bottom: 30px;
    border-bottom: 1px solid $primary;
    padding-bottom: 30px;
  }

  &__metadata {
    margin-bottom: 30px;
    border-bottom: 1px solid $primary;
    padding-bottom: 30px;
    color: $dark-theme;
    font-size: 1.1em;
    display: grid;
    grid-gap: 10px;
  }

  &__content {
    color: $dark-theme;
    font-size: 1.3em;
  }
}
